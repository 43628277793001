// Edlio Public Sites

.edlio .page {
	.width-constraint {
		display: flex;
		width: 100%;
	}

	.block {
		display: block;
		flex: 0 0 auto;

		&.layout {
			flex: 1 1 auto;
		}

		&.component {
			&.rich-text {
				strong {
					font-weight: 600;
				}

				em {
					font-style: italic;
				}

				sub {
					font-size: .8em;
					vertical-align: sub;
				}

				sup {
					font-size: .8em;
					vertical-align: super;
				}
			}
		}
	}

	&.vertically-oriented .width-constraint {
		flex-direction: column;
	}

	&.horizontally-oriented .width-constraint {
		flex-direction: row;
	}

	&.legacy {
		&.right-aligned {
			.main {
				order: -1;
			}

			.sidebar {
				order: 99;
			}
		}

		&.left-aligned {
			.main {
				order: 99;
			}

			.sidebar {
				order: -1;
			}
		}

		&.full-width {
			.main {
				order: 0;
			}

			.sidebar {
				display: none !important;
			}
		}

		&.hide-nav .sidebar nav {
			display: none !important;
		}
	}
	
	@import 'rich-text';
}

.rich-text {
  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }
  
  h2,
  h3 {
    font-weight: bold;
  }
  
  p {
    margin-bottom: 1em;
  }
  
  ul,
  ol {
    margin-bottom: 1em;
    
    li {
      margin-left: 1.236em;
    }
  }

  ul {
    list-style: disc;
  }
  
  ol {
    list-style: decimal;
  }
  
  table {
    width: 100%;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
}
